import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="পাঠ্যক্রম" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  পাঠ্যক্রম
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <Text mdxType="Text">
  মাদরাসাতুল ইল্‌ম মূলত একটি হিফয মাদ্রাসা। তবে ‘হিফয’ শুরু করার পূর্বেই প্রতিটি
  ছাত্র/ছাত্রীকে একই সাথে দুটি পাঠ্যক্রম সফলভাবে সমাপ্ত করতে হয়। পাঠ্যক্রম দুটির
  একটি হচ্ছে: দ্বীনিয়াত শিক্ষা পাঠ্যক্রম, এবং অপরটি: সাধারণ শিক্ষা পাঠ্যক্রম।
      </Text>
      <Text variant="h4" color="primary2" mdxType="Text">
  দ্বীনিয়াত শিক্ষা পাঠ্যক্রম
      </Text>
      <ul>
        <li parentName="ul">{`“শুধু কুর্‌আন হিফয-ই নয়, শিশু বয়স-ই দ্বীনের মৌলিক জ্ঞান অর্জনের উপযুক্ত সময়” এই পটভূমিতেই রচিত ‘মাদরাসাতুল ইলম’ এর ‘দ্বীনিয়াত শিক্ষা পাঠ্যক্রম’;`}</li>
        <li parentName="ul">{`‘শিশু থেকে শুরু করে চতুর্থ শ্রেণি পর্যন্ত মোট পাঁচটি শ্রেণি এই পাঠ্যক্রমের আওতাভুক্ত;`}</li>
        <li parentName="ul">{`প্রতিটি শ্রেণির ব্যাপ্তিকাল আনুমানিক আট মাস;`}</li>
        <li parentName="ul">{`কার্যক্রমটি মূলত ‘নূরানি ও নাযেরা’ বিভাগের ছাত্র/ছাত্রীদের জন্য প্রযোজ্য;`}</li>
        <li parentName="ul">{`দ্বীনিয়াত পাঠ্যক্রমের মাধ্যমে একজন ছাত্র/ছাত্রী যা যা অর্জন করবে (ইন শা-আল্লাহ)-`}
          <ul style={{
            marginLeft: '2rem',
            marginTop: '1rem',
            marginBottom: '1rem'
          }}>
  <li>শেষ দুই পারা কুর্‌আন হিফয</li>
  <li>২০০ ছোট ছোট ও ছন্দময় হাদীস হিফয</li>
  <li>‘হিসনুল মুসলিম’ থেকে প্রয়োজনীয় বেশ কিছু দু’আ হিফয</li>
  <li>আককীদাহ, আদব ও ফিকহ এর জরূরী কিছু জ্ঞান</li>
  <li>আরবী ভাষার উপর প্রাথমিক দক্ষতা</li>
          </ul>
        </li>
        <li parentName="ul">{`একজন ছাত্র/ছাত্রী ‘দ্বীনিয়াত পাঠ্যক্রম’ সফলভাবে সমাপ্তির মাধ্যমে ‘হিফয বিভাগে’ উত্তীর্ণ হতে পারবে;`}</li>
        <li parentName="ul">{`শিক্ষক নিজস্ব দ্বীনি চেতনার আলোকে দ্বীন শিক্ষাকে মুখস্থ নির্ভর না করে প্রয়োগিকভাবে ছাত্র/ছাত্রীর কাছে পাঠ উপস্থাপন করবেন, এ-ই ‘মাদরাসাতুল ইলম’ এর প্রত্যাশা।`}</li>
      </ul>
      <Box textAlign="center" mt={3} mdxType="Box">
  <a href="https://drive.google.com/file/d/1W4wRwu6vuIhKv9PeTwA_Ah7N4UkUVawU/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">দ্বীনিয়াত শিক্ষা পাঠ্যক্রম ডাউনলোড</Button>
  </a>
      </Box>
      <Text variant="h4" color="primary2" mdxType="Text">
  সাধারণ শিক্ষা পাঠ্যক্রম
      </Text>
      <ul>
        <li parentName="ul">{`“সৃষ্টিকে জানার মাধ্যমে সৃষ্টিকর্তার ক্ষমতার কিছুটা ধারণা লাভ করা” এ-ই ‘মাদরাসাতুল ইলম’ এর ‘সাধারণ শিক্ষা পাঠ্যক্রম’ এর মূল উদ্দেশ্য;`}</li>
        <li parentName="ul">{`তাছাড়া, যোগ্য নাগরিক হয়ে দ্বীন ও সমাজকে নেতৃত্ব দিতে, পাশাপাশি আল্লাহর রিযিক অনুসন্ধানের যোগ্যতা অর্জনে এ পাঠ্যক্রমের গুরুত্ব অনস্বীকার্য;`}</li>
        <li parentName="ul">{`প্রি-স্কুল থেকে শুরু করে পঞ্চম শ্রেণি পর্যন্ত মোট ছয়টি শ্রেণি এই পাঠ্যক্রমের আওতাভুক্ত;`}</li>
        <li parentName="ul">{`প্রতিটি শ্রেণির ব্যাপ্তিকাল আনুমানিক আট মাস;`}</li>
        <li parentName="ul">{`কার্যক্রমটি শুধুমাত্র ‘নূরানি ও নাযেরা’ বিভাগের ছাত্র/ছাত্রীদের জন্য প্রযোজ্য;`}</li>
        <li parentName="ul">{`প্রতিষ্ঠানের কোনো ছাত্র/ছাত্রী যখন ‘হিফয বিভাগে’ উত্তীর্ণ হবে তখন কর্তৃপক্ষ তার জন্য কার্যক্রমটি সাময়িকভাবে বন্ধ রাখতে পারে;`}</li>
        <li parentName="ul">{`এ পাঠ্যক্রম মূলত ‘জাতীয় শিক্ষাক্রম ও পাঠ্যপুস্তক বোর্ড’ (NCTB) ও ‘হাদীছ ফাউন্ডেশন’ এর বইগুলোর আলোকে প্রণীত। তবে, অন্যান্য গুরুত্বপূর্ণ বই ও প্রেসেন্টেশন যেখানো প্রযোজ্য মনে হয়েছে তা পাঠ্যক্রমে উল্লেখ করা হয়েছে;`}</li>
        <li parentName="ul">{`শিক্ষক ও অভিভাবক নিজস্ব চিন্তাশক্তি প্রয়োগ করে শিক্ষাকে মুখস্থ নির্ভর না করে প্রয়োগিকভাবে ছাত্র/ছাত্রীর কাছে পাঠ উপস্থাপন করবেন, এ-ই ‘মাদরাসাতুল ইলম’ এর প্রত্যাশা।`}</li>
      </ul>
      <Box textAlign="center" mt={3} mdxType="Box">
  <a href="https://drive.google.com/file/d/1t3t9FRXlCQyvVowVeMGOPhGZxHQqD-pI/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">সাধারণ শিক্ষা পাঠ্যক্রম ডাউনলোড</Button>
  </a>
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      